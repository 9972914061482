<template>
  <button @click="handleGetOutJSON">JSON output</button>
  <div style="display: flex; align-items: flex-start; padding-bottom: 50px">
    <h3 style="margin-right: 10px">Drawing mode</h3>
    <button
      :disabled="isCanDraw"
      :style="{
        backgroundColor: isCanDraw ? 'green' : 'lightgray',
        color: isCanDraw ? 'white' : 'black',
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px',
        cursor: !isCanDraw ? 'pointer' : 'default',
      }"
      @click="handleDrawAccess(true)"
    >
      {{ "on" }}
    </button>
    <button
      :disabled="!isCanDraw"
      :style="{
        backgroundColor: !isCanDraw ? 'green' : 'lightgray',
        color: !isCanDraw ? 'white' : 'black',
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px',
        cursor: isCanDraw ? 'pointer' : 'default',
      }"
      @click="handleDrawAccess(false)"
    >
      {{ "off" }}
    </button>
    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 20px;
      "
      v-if="selectedRect"
    >
      <label for="description_input">Write your description</label>
      <InputText
        id="description_input"
        @keydown.enter="selectedRect = null"
        v-model="selectedRect.description"
      />
    </div>

    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 20px;
      "
      v-if="selectedRect"
    >
      <label for="description_input">Choose shape color</label>
      <ColorPicker v-model="selectedRect.config.fill" />
    </div>
  </div>
  <div class="container canvas-area">
    <v-stage
      :config="stageSize"
      @mouseDown="handleClick"
      @mousemove="handleMouseMove"
      ref="stage"
    >
      <v-layer ref="layer">
        <v-line :config="lineSize"></v-line>
        <v-rect
          v-for="(point, index) in points"
          :key="index"
          :config="{
            x: point[0] - 6 / 2,
            y: point[1] - 6 / 2,
            width: 6,
            height: 6,
            fill: 'white',
            stroke: 'black',
            strokeWidth: 2,
            draggable: true,
            hitStrokeWidth: index === 0 ? 12 : 0,
          }"
          @dragmove="handleDragMovePoint"
          @mouseover="handleMouseOver($event, index)"
          @mouseout="handleMouseOut($event, index)"
        />
        <v-line
          v-for="(lines, index) in recs"
          :key="index"
          :config="{
            points: lines.positions,
            ...lines.config,
          }"
          @dragend="handleDragOutPoint($event, lines.id)"
          @click="handleClickRect(lines)"
        ></v-line>
        <v-text
          v-if="selectedRect?.description"
          ref="text"
          :config="{
            x: selectedRect.x,
            y: selectedRect.y,
            fontSize: 20,
            text: selectedRect.description,
            fill: 'black',
          }"
        />
      </v-layer>
    </v-stage>
  </div>
</template>

<script>
import { selectionMapPointsMocks } from "@/mocks";

export default {
  data() {
    return {
      stageSize: {
        width: 500,
        height: 500,
      },
      lineSize: {
        points: [],
        stroke: "red",
        strokeWidth: 2,
        closed: true,
      },
      points: [],
      curMousePos: [0, 0],
      isMouseOverStartPoint: false,
      isFinished: false,
      recs: selectionMapPointsMocks,
      selectedRect: null,
      isCanDraw: false,
    };
  },
  watch: {
    "selectedRect.config.fill"(val) {
      if (val) {
        this.recs = this.recs.map((el) =>
          el.id === this.selectedRect.id
            ? {
                ...el,
                config: {
                  ...el.config,
                  fill: val.includes("#") ? val : `#${val}`,
                  stroke: el.config.fill ? el.config.fill : "red",
                },
              }
            : el
        );
      }
    },
    "selectedRect.description"(val) {
      if (val) {
        this.recs = this.recs.map((el) =>
          el.id === this.selectedRect.id ? { ...el, description: val } : el
        );
      }
    },
    selectedRect(val) {
      if (val) {
        this.recs = this.recs.map((el) =>
          el.id === val.id
            ? {
                ...el,
                config: {
                  ...el.config,
                  stroke: el.config.fill ? el.config.fill : "black",
                },
              }
            : el
        );
      } else {
        this.recs = this.recs.map((el) => ({
          ...el,
          config: {
            ...el.config,
            stroke: el.config.fill ? el.config.fill : "red",
          },
        }));
      }
    },
  },
  methods: {
    handleDrawAccess(value) {
      this.isCanDraw = value;
    },
    handleGetOutJSON() {
      console.log("output", JSON.stringify(this.recs));
    },
    handleClickRect(item) {
      if (!this.isCanDraw) {
        this.selectedRect = item;
      }
    },
    getMousePos(stage) {
      return [stage.getPointerPosition().x, stage.getPointerPosition().y];
    },
    handleClick(event) {
      const stage = event.target.getStage();
      const mousePos = this.getMousePos(stage);

      if (this.isFinished || !this.isCanDraw) {
        this.selectedRect = null;
        return;
      }
      if (this.isMouseOverStartPoint && this.points.length >= 3) {
        this.isFinished = true;
        this.recs.push({
          id: Date.now().toString(),
          positions: this.lineSize.points,
          config: {
            stroke: "red",
            strokeWidth: 2,
            closed: true,
            fill: "",
            draggable: true,
          },
          x: mousePos[0],
          y: mousePos[1],
          description: "",
        });
        this.points = [];
        this.lineSize.points = [];
        this.isFinished = false;
      } else {
        this.points = [...this.points, mousePos];
      }
    },
    handleMouseMove(event) {
      const stage = event.target.getStage();
      this.curMousePos = this.getMousePos(stage);
      this.flattenedPoints();
    },
    handleMouseOverStartPoint(event) {
      if (this.isFinished || this.points.length < 3) return;
      event.target.scale({ x: 2, y: 2 });
      this.isMouseOverStartPoint = true;
    },
    handleMouseOutStartPoint(event) {
      event.target.scale({ x: 1, y: 1 });
      this.isMouseOverStartPoint = false;
    },
    handleDragMovePoint(event) {
      const points = this.points;
      const index = event.target.index - 1;
      const pos = [event.target.attrs.x, event.target.attrs.y];
      this.points = [
        ...points.slice(0, index),
        pos,
        ...points.slice(index + 1),
      ];
    },
    handleDragOutPoint(event, id) {
      this.recs = this.recs.map((el) =>
        el.id === id
          ? {
              ...el,
              x: this.$refs.stage.getNode().getPointerPosition().x,
              y: this.$refs.stage.getNode().getPointerPosition().y,
            }
          : el
      );
    },
    handleMouseOver(event, index) {
      if (index === 0) {
        this.handleMouseOverStartPoint(event);
      }
    },
    handleMouseOut(event, index) {
      if (index === 0) {
        this.handleMouseOutStartPoint(event);
      }
    },
    flattenedPoints() {
      return (this.lineSize.points = this.points
        ?.concat(this.isFinished ? [] : this.curMousePos)
        ?.reduce((a, b) => a.concat(b), []));
    },
  },
};
</script>

<style>
.canvas-area {
  background: round url("../assets/room-plan.jpg") !important;
}

.container {
  width: 504px;
  height: 504px;
  border: 2px solid black;
}
</style>
