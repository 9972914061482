<template>
  <div class="p-grid">
    <div
        style="
        margin: 10px;
        box-shadow: 1px 1px 1px lightgrey;
        font-size: 12px;
        background: #fff;
      "
        :style="expandSidebarDialog ? 'width:70rem;' : 'width:20rem;'"
    >
      <h3>{{ _t("active_lone_workers") }}</h3>
      <!--      <Button-->
      <!--        label="Today"-->
      <!--        style="width: 70%"-->
      <!--        icon="pi pi-calendar"-->
      <!--        class="-->
      <!--          p-button-raised p-button-secondary p-button-outlined p-button-rounded-->
      <!--        "-->
      <!--      />-->
      <DataTable
          ref="tree"
          v-model:selection="selectedItem"
          :dataKey="tableDataKey"
          :lazy="true"
          :page="loadedLwPage"
          :paginator="true"
          :paginatorTemplate="'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'"
          :rowHover="true"
          :rows="lwTableRows"
          :scrollable="true"
          :totalRecords="totalRecords"
          :value="loneworkers"
          class="tight"
          scrollHeight="75vh"
          selectionMode="single"
          stripedRows
          @page="selectTablePage($event)"
          @row-click="this.selectLoneWorker($event.data.id)"
      >
        <Column
            field="name"
            :header="_t('Name')"
            :style="
            'width: ' + 100 + '%; word-break: break-all; min-height: 38px;'
          "
        >
          <template #body="{ data }">
            <span class="wrap" style="word-break: break-all; padding-left: 5px">
              {{ data.initiator.surname }} , {{ data.initiator.name }}
            </span>
          </template>
        </Column>
      </DataTable>
    </div>
    <transition name="slide-fade" v-if="loneworkerData">
      <Card class="p-col" style="overflow-x: scroll; width: 100%">
        <template #title>
          <span
              style="
              padding: 5px 10px;
              background-color: #d0e3b8;
              font-size: 20px;
              margin-bottom: 20px;
            "
          >{{ loneworkerData.initiator.surname }}
            {{ loneworkerData.initiator.name }}</span
          >
        </template>
        <template #content>
          <div
              style="
              display: flex;
              flex-direction: row;
              justify-content: space-evenly;
            "
          >
            <div style="width: 100%; padding-right: 20px">
              <div
                  style="
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                "
              >
                <span>Route started:</span>
                <span>{{ loneworkerData.started }}</span>
              </div>
              <div
                  style="
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                "
                  v-if="loneworkerData.positions.length"
              >
                <span @click="click(loneworkerData)">Last position:</span>
                <span
                >{{
                    loneworkerData.positions[
                    loneworkerData.positions.length - 1
                        ].latitude
                  }}
                  {{
                    loneworkerData.positions[
                    loneworkerData.positions.length - 1
                        ].longitude
                  }}</span
                >
              </div>
              <div v-else>
                <span>Positions not found</span>
              </div>
            </div>
            <div v-if="loneworkerData.positions.length">
              <Map :locations="loneworkerData.positions"></Map>
            </div>
          </div>
        </template>
      </Card>
    </transition>
  </div>
</template>

<styles></styles>

<script>
import Map from "../components/Map.vue";
import {getLoneworkerDetails, getLoneworkerListActive} from "@/api/loneworker";

export default {
  name: "loneworkerActive",
  components: {
    Map: Map,
  },
  data() {
    return {
      loading: false,
      expandSidebarDialog: false,
      selectedItem: null,
      tableDataKey: "id",
      loadedLwPage: 0,
      lwTableRows: 100,
      pageSize: ["5", "10", "20", "50"],
      totalRecords: 100,
      currentPage: 0,
      loneworkers: null,
      loneworkerData: null,
      isMapOpen: false,
    };
  },
  mounted() {
    this.getLoneworkerData();
  },
  methods: {
    selectLoneWorker(id) {
      this.getLoneworkerById(id);
    },
    selectTablePage(item) {
      this.loading = true;
      this.loadedLwPage = item.page;
    },
    getLoneworkerById(id) {
      this.loneworkerData = null;
      getLoneworkerDetails(id)
          .then((res) => {
            if (res.data) {
              this.loneworkerData = res.data;
              //MOCK MAP DATA
              this.loneworkerData.positions = [
                {
                  accuracy: 31.391,
                  altitude: 217.89999389648438,
                  bearing: 293.327,
                  checkin: false,
                  created: "2023-04-28  10:44:17",
                  id: 271,
                  latitude: 35.113392,
                  longitude: 33.3557463,
                  new: false,
                  speed: 0.245465
                },
                {
                  accuracy: 31.391,
                  altitude: 217.89999389648438,
                  bearing: 293.327,
                  checkin: false,
                  created: "2023-04-28  10:44:17",
                  id: 272,
                  latitude: 35.213392,
                  longitude: 33.3557463,
                  new: false,
                  speed: 0.245465
                },
                {
                  accuracy: 31.391,
                  altitude: 217.89999389648438,
                  bearing: 293.327,
                  checkin: false,
                  created: "2023-04-28  10:44:17",
                  id: 273,
                  latitude: 35.313392,
                  longitude: 33.4557463,
                  new: false,
                  speed: 0.245465
                },
                {
                  accuracy: 31.391,
                  altitude: 217.89999389648438,
                  bearing: 293.327,
                  checkin: false,
                  created: "2023-04-28  10:44:17",
                  id: 274,
                  latitude: 35.413392,
                  longitude: 33.5557463,
                  new: false,
                  speed: 0.245465
                },
                {
                  accuracy: 31.391,
                  altitude: 217.89999389648438,
                  bearing: 293.327,
                  checkin: false,
                  created: "2023-04-28  10:44:17",
                  id: 275,
                  latitude: 35.513392,
                  longitude: 33.6557463,
                  new: false,
                  speed: 0.245465
                }
              ]
              this.isMapOpen = true;
            }
          })
          .catch((err) => console.log(err));
    },
    getLoneworkerData() {
      getLoneworkerListActive({
        currentPage: 0,
        dateOrder: "ASCENDING",
        pageSize: 20,
      })
          .then((res) => {
            if (res.data) {
              this.loneworkers = res.data.list;
            }
          })
          .catch((err) => console.log(err));
    },
    selectAlarmPlan(id) {
      this.selectedItem = id;
      this.isMapOpen = true;
    },
  },
};
</script>
