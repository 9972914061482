import request from "@/api/request";

export function getLoneworker(data) {
  return request({
    url: "/loneworker",
    method: "post",
    data,
  });
}

export function getLoneworkerDetails(id) {
  return request({
    url: `/loneworker/details/${id}`,
    method: "get",
  });
}

export function getLoneworkerHistory(listRequest) {
  return request({
    url: `loneworker/history`,
    method: "post",
    data: {
      listRequest,
    },
  });
}

export function getLoneworkerListActive(listRequest) {
  return request({
    url: `loneworker/list-active`,
    method: "post",
    data: {
      listRequest,
    },
  });
}
