<template>
  <GMapMap
    :center="center"
    :zoom="10"
    map-type-id="terrain"
    streetViewControl
    scaleControl
    zoomControl
  >
    <GMapCluster
      :zoomOnClick="true"
      :minimumClusterSize="2"
      :styles="clusterStyles"
    >
      <GMapMarker
        :key="index"
        v-for="(m, index) in markers"
        :label="{ text: m.label, color: 'black' }"
        :position="m.position"
        :clickable="true"
        :draggable="false"
        @click="handleClickMarker(m)"
        :icon="{
          url: m.id === markers[0].id ? markerPathYellow : markerPathGreen,
          scaledSize: m.id === markers[markers.length -1].id ? { width: 25, height: 25 } : { width: 15, height: 15 },
          labelOrigin: { x: -10, y: 0 },
        }"
      >
        <GMapInfoWindow
          :opened="isMarkerOpen && m.id === markers[markers.length - 1].id"
          :closeclick="true"
          @closeclick="this.isMarkerOpen = false"
        >
          <div style="display: flex; flex-direction: column">
            <span>{{
              "Label : " + (m.label.trim() ? m.label : "Last point")
            }}</span>
            <span>{{ "Latitude: " + m.position.lat }}</span>
            <span>{{ "Longitude: " + m.position.lng }}</span>
          </div>
        </GMapInfoWindow>
      </GMapMarker>
    </GMapCluster>
  </GMapMap>
</template>

<script>
export default {
  props: {
    locations: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  watch: {
    locations(value) {
      this.setMarkersObj(value);
    },
  },
  mounted() {
    this.setMarkersObj(this.locations);
    this.setZoomCenter(this.locations);
  },
  data() {
    return {
      markerPathGreen: require("../assets/map-marker-green.svg"),
      markerPathYellow: require("../assets/map-marker-yellow.svg"),
      clusterStyles: [
        {
          textColor: "white",
          textSize: 25,
          margin: "0 0 10px 10px",
          url: require("../assets/map-marker-green.svg"),
          width: 170,
          height: 70,
          anchorText: [-8, -73],
        },
      ],
      center: {},
      markers: [],
      isMarkerOpen: false,
    };
  },
  methods: {
    handleClickMarker(m) {
      this.center = m.position;
      this.isMarkerOpen = m.id === this.locations[this.locations.length - 1].id;
    },
    setMarkersObj(value) {
      return (this.markers = value.map(({ id, latitude, longitude }) => ({
        id,
        label: " ",
        position: { lat: latitude, lng: longitude },
      })));
    },
    setZoomCenter(value) {
      const latCenter = value.map((val) => val.latitude).sort((a, b) => a - b)[
        value.length - 1
      ];
      const lngCenter = value.map((val) => val.longitude).sort((a, b) => b - a)[
        value.length - 1
      ];
      this.center = { lat: latCenter, lng: lngCenter };
    },
  },
};
</script>

<style>
body {
  margin: 0;
}

.vue-map {
  width: 50rem;
  height: 50rem;
}
</style>
